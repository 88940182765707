import axios from 'axios';
import { BaseZindeUrl } from '../../configs/baseConfigs';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');



export const getProducts = createAsyncThunk(
    'product/getProducts', 
    async (val, { dispatch, getState }) => {
    const response = await axios.get(`${BaseZindeUrl}v1/d36e2b82-0db3-4507-be16-bf621d59b5a4`).then((res)=>{
        dispatch(setProducts(res.data))
    });
    const data = await response.data;
    return data;
  });
  

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
    },
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        }
    },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;
